<template>
  <div class="flex h-full">
    <div class="bg-mask"></div>
    <div class="bg-image"></div>
    <div class="relative m-auto z-10 splash">
        <img src="../assets/images/logo/logo.png" alt="">
      <div class="">
        <p class="mt-52">به بانک بنگاه خوش آمدید</p>
        <button class="btn-splash" @click="showLoginMode = ! showLoginMode">
          <svg xmlns="http://www.w3.org/2000/svg" width="11.985" height="11.985" viewBox="0 0 11.985 11.985">
            <path id="ic_arrow_back_24px" d="M15.985,9.243H6.869l4.187-4.187L9.993,4,4,9.993l5.993,5.993,1.056-1.056-4.18-4.187h9.116Z" transform="translate(-4 -4)" fill="#fafafa"/>
          </svg>
        </button>
      </div>
    </div>
    <div :style="[!showLoginMode ? { overflow: 'hidden' , height: 0 ,opacity: 0,transition: 'height 0ms 400ms, opacity 400ms 0ms'  } : {height: 'auto', opacity: 1, transition: 'height 0ms 0ms, opacity 600ms 0ms'}]" class="blur-box" id="loginMode__box">
      <div class="text-center">
        <p class="pt-4 pb-10 font-medium">لطفا نوع ورود خودتو مشخص کن</p>
        <router-link :to="{name : 'UserLogin'}" class="btn-custom mb-5">
          کاربر عادی
          <span class="svg-btn-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 24.826) rotate(-90)" fill="#ff7400"/>
</svg>
        </span>
        </router-link>
        <router-link :to="{name : 'RealEstateLogin'}" class="btn-custom">
          بنگاه دار
          <span class="svg-btn-icon ">
            <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 24.826) rotate(-90)" fill="#ff7400"/>
</svg>
        </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "../plugins/axios";

export default {
  name : 'LoginMode',
  data(){
    return{
      showLoginMode : false,
    }
  },
  methods :{
    checkUser(user){
        if (user.type === 'real-estate'){
          this.$router.push({name : 'HomeRealEstate'})
        }else if (user.type === 'user'){
          this.$router.push({name : 'HomeCustomer'})
        }
    }
  },
  created() {
    if ('user-token' in localStorage){
      axios.get('/users').then(async ({data})=>{
        await localStorage.setItem('user', JSON.stringify(data.data))
        let user = JSON.parse(localStorage.getItem('user'))
        this.checkUser(user)
      })
    }



  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>